<template>
  <ion-page>
    <Toolbar
      title="Matterport Tags"
      show-back-button
      @onBackButtonClicked="() => {}"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button @click="$router.replace(goBackUrl)">
            <ion-icon
              slot="icon-only"
              :icon="arrowBack"
            />
          </ion-button>
        </ion-buttons>
      </template>

      <template #trailing>
        <ion-buttons
          slot="primary"
        >
          <ion-button
            slot="end"
            color="light"
            @click="downloadPdf"
          >
            Download
          </ion-button>
        </ion-buttons>
      </template>
    </Toolbar>
    
    <ion-content>
      <div>
        <div class="flex">
          <div class="maengel-sidebar-wrapper">
            <div class="sidebar-heading w-full p-4">
              <div class="flex items-center">
                <h2
                  class="subtitle flex-1"
                  style="margin-top: 0; margin-bottom: 0;"
                >
                  Mängelliste
                </h2>
                <ion-button
                  color="light"
                  size="small"
                  @click="syncBestandsaufnahme"
                >
                  Synchronisieren
                </ion-button>
              </div>
              <div class="flex mt-2 items-center">
                <input
                  id="checkbox"
                  v-model="showOnlyUnfinished"
                  type="checkbox"
                  class="mr-2"
                >
                <label for="checkbox">Zeige nur offene Mängel</label>
              </div>
            </div>

            <div class="scrollbar pt-4">
              <hzba-group
                v-for="mangel in maengel"
                v-show="!showOnlyUnfinished || !mangel.tag3d"
                :key="mangel"
                hide-progress
                :title="`${mangel.titel()} (${mangel.maluspunkte() } MP)`"
                class="mangel-item mb-4"
                :outer-margin="false"
                smaller-title
              >
                <div class="px-4">
                  <div class="mangel-row">
                    <div class="label">
                      Freitext
                    </div>
                    <div class="content">
                      {{ mangel.freitext }}
                    </div>
                  </div>

                  <div class="mangel-row">
                    <div class="label">
                      Relevant
                    </div>
                    <div class="content">
                      {{ mangel.relevant ? 'Ja' : 'Nein' }}
                    </div>
                  </div>

                  <div class="mangel-row">
                    <div class="label">
                      Handlungsbedarf - Tätigkeit
                    </div>
                    <div class="content">
                      {{ mangel.vorlage.handlungsbedarfTaetigkeit }}
                    </div>
                  </div>
                  <div class="mangel-row">
                    <div class="label">
                      Handlungsbedarf - Zeitpunkt
                    </div>
                    <div class="content">
                      {{ mangel.vorlage.handlungsbedarfZeitpunkt }}
                    </div>
                  </div>

                  <div class="mangel-row">
                    <div class="label">
                      Kategorie
                    </div>
                    <div class="content">
                      {{ mangel.vorlage.kategorie }}
                    </div>
                  </div>

                  <div class="mangel-row">
                    <div class="label">
                      Bilder
                    </div>
                    <div class="content">
                      <div v-if="!mangel.bilder || mangel.bilder.length === 0">
                        -
                      </div>
                      <img
                        v-for="(bild, key) in mangel.bilder"
                        :key="key"
                        :src="getPhotoUrl(bild)"
                        width="64"
                        height="64"
                        class="object-cover mr-2"
                      >
                    </div>
                  </div>


                  <!--                <pre>-->
                  <!--                {{ mangel.matterport }}-->
                  <!--                </pre>-->

                  <ion-button
                    v-if="!(mangel.tag3d && mangel.tag3d)"
                    @click="addMangelTag(mangel)"
                  >
                    Tag hinzufügen
                  </ion-button>

                  <ion-button
                    v-if="mangel.tag3d"
                    fill="flat"
                    @click="removeMangelTag(mangel)"
                  >
                    Tag löschen
                  </ion-button>
                </div>
              </hzba-group>
            </div>
          </div>

          <iframe
            id="showcase"
            class="iframe"
            frameborder="0"
            allowFullScreen
            allow="xr-spatial-tracking"
            width="100%"
            height="1000px"
          />

          <!--        <div>-->
          <!--          Setting-->
          <!--          <ion-button @click="toggleAddButton">-->
          <!--            Toggle Add button-->
          <!--          </ion-button>-->
          <!--        </div>-->
        </div>


        <!--        <pre style="margin-top: 305px">-->
        <!--                  {{ mergedMaengel }}-->
        <!--                </pre>-->
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">

import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import Toolbar from "@/components/Navigation/Toolbar";
import useBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSubpage";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import useMatterportTagManagement from "@/composables/useMatterportTagManagement";
import { useStore } from "@/composables/useTypedStore";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { getPhotoUrl } from "@/utilities/get-media-url";
import {
IonButton,
IonButtons,
IonContent,
IonIcon,
IonPage,
onIonViewWillEnter,
onIonViewWillLeave
} from "@ionic/vue";
import { computed, onMounted, ref, watch } from "vue";
export default {
  name: "matterport.vue",
  components: {
    HzbaGroup,
    IonPage,
    IonContent,
    IonButton,
    Toolbar,
    IonButtons,
    IonIcon,
  },
  setup() {
    const currentMangel = ref();
    const store = useStore();
    let isMPready = false;
    let didSetup = false;

    const showOnlyUnfinished = ref(false);

    // Hzba Detail Page View Setup
    const { isHzbaLoaded, ba, goBackUrl } = useBestandsaufnahmeSubpage({ onIonViewWillEnter, onIonViewWillLeave });
    const maengel = computed(() => { return ba.value?.getFiredMaengel() })

    const mergedAndFilteredMaengel = computed(() => {
      if (showOnlyUnfinished.value) {
        return maengel.value?.filter(el => el.tag3d)
      }
      return maengel.value
    })

    // Matterport Setup
    const modelSid = "ELw3aQ5i69b";
    const mpTagManagement = useMatterportTagManagement(modelSid);

    const findEditableMangelzuordnung = (mangel: Mangelzuordnung): Mangelzuordnung | undefined => {
      return ba.value?.getFiredMaengel().find(m => {
        return mangel.id === m.id; // TODO reactivate here && mangel.uid === m.uid && mangel.itemUid === m.itemUid
      })
    }

    mpTagManagement.setTagSetListener((pos: any) => {
      const mangel = currentMangel.value;
      const mangelzuordnung = mangel && findEditableMangelzuordnung(mangel)

      if (!mangelzuordnung) {
        console.error('No mangelzuordnung found.')
        return;
      }

      if (!mangelzuordnung.tag3d) { mangelzuordnung.tag3d = {} }
      mangelzuordnung.tag3d = pos;


      store.dispatch('currentHzba/saveHzbaLocally');
    })

    function addMangelTag(mangel: Mangelzuordnung) {
      if (!mangel) { return;}

      currentMangel.value = mangel;

      mpTagManagement.startAddTag({
        label: mangel.titel(),
        description: `${mangel.maluspunkte()} MP\n${mangel.kategorie()}`,
        media: {
          type: "mattertag.media.photo",
          src: getPhotoUrl(currentMangel.value.bilder[0])
        }
      })
    }

    function removeMangelTag(mangel: Mangelzuordnung) {
      if (!mangel || !mangel.tag3d) { return; }

      mpTagManagement.removeTag(mangel.tag3d.sid)

      const m = findEditableMangelzuordnung(mangel);

      // Remove tag3d but keep the id from the strapi backend
      // const id = m.tag3d.id;
      // m.tag3d = { id };
      if (m) {
        m.tag3d = undefined;
      }

      store.dispatch('currentHzba/saveHzbaLocally');
    }


    const setupTags = () => {

      if (maengel.value && maengel.value.length > 0 && isMPready && !didSetup) {
        didSetup = true;
        const maengelWithMatterport = maengel.value.filter(m => m.tag3d);
        const tags = maengelWithMatterport.map(m => JSON.parse(JSON.stringify(m.tag3d)));

        mpTagManagement.setupTags(tags, (sids: any) => {
          for (let i = 0; i < sids.length; i++) {
            const m = findEditableMangelzuordnung(maengelWithMatterport[i]);
            if (m) {
              m.tag3d.sid = sids[i]
            }
          }
        });
      }
    }

    watch(() => maengel, (newVal, oldVal) => {
      setupTags();
    })

    onMounted(() => {

      const onMPReady = () => {
        isMPready = true;
        setupTags();
      }

      mpTagManagement.mountMe(onMPReady);
    })


    const { syncBestandsaufnahme, submitBestandsaufnahme } = useBestandsaufnahmeUpload();


    return {
      addMangelTag,
      currentMangel,
      removeMangelTag,
      getPhotoUrl,
      showOnlyUnfinished,
      maengel,
      mergedAndFilteredMaengel,
      syncBestandsaufnahme,
    }
  }
}
</script>

<style scoped lang="scss">

.sidebar-heading {
  background: var(--white100);
  border-bottom: 1px solid $grey-300;
}

.maengel-sidebar-wrapper {
  width: 500px;
  background: #f3f5f9;
  overflow: hidden;
  height: calc(100vh - 57px);
  //overflow-y: scroll;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scrollbar {
  overflow-y: auto;
  //height: 100%;
  padding-right: 24px;
  padding-left: 24px;
}


.mangel-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey-300;
  font-size: 14px;

  .label {
    flex-shrink: 0;
    width: 150px;
  }
}

.iframe {
  max-height: calc(100vh - 57px);
}

</style>