import User from "@/models/user";
// import Immobilie from "@/models/immobilie.model";

import { uploadPhoto } from "@/api/UploadApi";
import useAuth from "@/composables/useAuth";
import CachedPhoto from "@/models/photo/cached-photo.model";
import { logger } from "@/utilities/logging";
import { computed, ComputedRef } from "vue";
import { useRouter } from "vue-router";
import apiClient, { strapiApiUrl } from "@/api/api";

export default function useUser() {

    const user: ComputedRef<User | null> = computed(() => User.query().first());

    function isImmobilienverwalter(): boolean {
        return user.value?.role?.id === 2;
    }
    function isGutachter(): boolean {
        return user.value?.role?.id === 3;
    }
    function isProjektverantwortlicher(): boolean {
        return user.value?.role?.id === 1;
    }
    function isLeser(): boolean {
        return user.value?.role?.id === 5;
    }
    function isBearbeiter(): boolean {
        return user.value?.role?.id === 6;
    }

    const canCreateUpdateObject = computed(() =>  {
        return isBearbeiter() || isProjektverantwortlicher();
    });

    const canCreateSurvey = computed(() =>  {
        return isBearbeiter() || isProjektverantwortlicher();
    });

    const canUpdateBegehungsDate = computed(() =>  {
        return user.value?.organisation?.featureFlags?.survey?.editDates;
    });

    const canUpdateSichtungsDate = computed(() =>  {
        return user.value?.organisation?.featureFlags?.survey?.editDates;
    });

    const canDownloadImages = computed(() => {
        return isLeser() || isBearbeiter() || isProjektverantwortlicher();
    });

    async function fetchUser() {
        try {
            const res = await User.api().get(`/users/me`);
            User.dispatch('$replaceLocally', { data: res.getDataFromResponse() }); 
            logger.defaultMeta.userId = (res.getDataFromResponse() as any).id;
        } catch (error) {
            if (error.message === "Network Error") {
                await User.dispatch("$fetchFromLocal");
                logger.error(`Couldn't fetch user: Network Error`);
            } else if(error.response.status === 401) {
                const router = useRouter();
                logger.error(`Couldn't fetch user: HTTP error 401`);
                router.push('/logout')
            } else {
                console.error(error);
                logger.error(`Couldn't fetch user: ${error}`);
            }
        }
    }

    async function fetchOrganisationUsers() {
        try {
            if (user.value && user.value?.organisation?.id) {
                const response = (await apiClient.get(`${strapiApiUrl}/users?filters[organisation][id][$eq]=${user.value.organisation.id}`)).data;
                return response;
            } else {
                console.error("No organisation id found for user. Cannot fetch organisation users.");
                return [];
            }
        } catch (err: any) {
            console.error("Failed fetching organisation users:", err);
            return [];
        }
    }

    async function updateProfileImage(photo: CachedPhoto) {
        if (!user.value || !user.value?.id) {
            return;
        }

        const res = await User.api().post(`/other/resetProfilbild`, {
            profilbild: undefined,
        });

        await uploadPhoto(photo, 'plugin::users-permissions.user', user.value?.id.toString(), 'profilbild', user.value.username + ".jpeg", 'users-permissions');

        await fetchUser();
        logger.defaultMeta.userId = user.value.id;

        logger.info(`User ${user.value.id} updated profile image`);
    }

    async function isLoggedIn() {
        const auth = useAuth();

        if (!auth.hasToken()) return false;

        if (auth.hasToken() && !user.value) {
            try {
                await fetchUser();
            } catch (error) {
                console.error(error);
            }
        }

        return !!user.value;
    }

    const isApproved = computed(() => {
        return user.value?.role?.id !== 4;
    })

    return {
        user,
        isLoggedIn,
        updateProfileImage,
        // addImmoPhoto,
        fetchUser,
        isGutachter,isProjektverantwortlicher,
        isApproved,
        isImmobilienverwalter,
        isLeser,
        isBearbeiter,
        canCreateUpdateObject,
        canCreateSurvey,
        canUpdateBegehungsDate,
        canUpdateSichtungsDate,
        canDownloadImages,
        fetchOrganisationUsers
    };
}