import { useIdentifierFrageFinder, useIdentifierFragenblockFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import useAlert from '@/composables/useAlert';
import { i18n } from '@/i18n';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { Frage } from '@/models/ba/Frage';
import { BaNodeFrageAndFragenblock, sortArrayPosition, ToJsonSettings } from '@/models/ba/interfaces/IBaNode';
import { FragenblockConfig, FragenblockFlatJson, FragenblockJson } from '@/models/ba/interfaces/IFragenblock';
import { ExtendedMSERow } from '@/models/ba/interfaces/IGenericPdfDataRow';
import { FiredMaengelFilterSettings } from '@/models/ba/interfaces/IMangelzuordnung';
import { LogikElement } from '@/models/ba/LogikElement';
import { MaengelReport } from '@/models/ba/MaengelReport';
import { Mangelzuordnung } from '@/models/ba/Mangelzuordnung';
import Localization from '@/models/localization.model';
import { APhoto } from '@/models/photo/a-photo.model';
import getLocalization from '@/utilities/get-localization';
import { isEmptyObject } from '@/utilities/helper';
import { generateUUID } from '@ionic/cli/lib/utils/uuid';

const labelFragenblockJson = (label?: string | undefined): FragenblockJson => {
  return {
    identifier: 'INTERNAL_LABEL_GROUP',
    titel: {
      en: 'Label',
      de: 'Bezeichnung',
    },
    arrayPosition: -1,
    frages: [
      {
        identifier: 'INTERNAL_LABEL',
        titel: {
          en: 'Label',
          de: 'Bezeichnung',
        },
        eingabeTyp: 'Text',
        eingabeText: label,
      },
    ],
  };
};

export class Fragenblock implements BaNodeFrageAndFragenblock, FragenblockFlatJson {
  /**
   * Fields stored as primitives
   */
  public id?: number;
  public titel: Localization;
  public createdAt?: string;
  public updatedAt?: string;
  public locale?: string;
  public blockTyp?: string;
  public arrayPosition: number;
  public uid?: string;
  public identifier: string;
  public anzeigen?: boolean;
  public config: FragenblockConfig = {};
  public geoJson?: any;

  /**
   * Fields stored as classes
   */
  public fragenblocks: Fragenblock[] = [];
  public frages?: Frage[] = [];
  public anzeigelogik?: LogikElement;
  public freischaltunglogik?: LogikElement;
  public freieMangels?: Mangelzuordnung[];

  /**
   * Additional helper fields
   */
  public path: string;
  public showErrors = false;
  private isDirty = false;
  public isShowDetectorSetup = false;
  public isUnlockDetectorSetup = false;
  private unlocked = true;

  constructor(json: FragenblockJson, parentPath: string, public mode?: number) {
    this.id = json.id;
    this.titel = json.titel;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.locale = json.locale;
    this.blockTyp = json.blockTyp;
    this.arrayPosition = json.arrayPosition;
    this.uid = json.uid || generateUUID();
    this.identifier = json.identifier;
    this.config = json.config || {};
    this.geoJson = json.geoJson

    this.anzeigen = json.anzeigen === undefined ? true : json.anzeigen;

    this.path = parentPath + '.' + json.identifier + (this.isInstanceOfMultiple() ? `[${this.uid}]` : '');

    this.anzeigelogik = json.anzeigelogik && new LogikElement(JSON.parse(JSON.stringify(json.anzeigelogik)));
    this.freischaltunglogik = json.freischaltunglogik && new LogikElement(JSON.parse(JSON.stringify(json.freischaltunglogik)));
    this.freieMangels = (json.freieMangels && json.freieMangels.map((el) => new Mangelzuordnung(el, this.path, mode))) || [];

    const paramFragenblocks = json.fragenblocks?.sort(sortArrayPosition).map((el) => new Fragenblock(el, this.path, mode));
    /**
     * According to Markus Band labels of 1..N Components are an unwanted feature so we do not activate it.
     */
    // if (mode && this.blockTyp?.toLowerCase() === 'mehrfach' && !paramFragenblocks?.find(el => el.identifier === labelFragenblockJson().identifier)) {
    //     //only add the "fragenblock label" if it's within a modal && the parent is 1..N && there is no other "label" Fragenblock yet.
    //     this.fragenblocks.push(new Fragenblock(labelFragenblockJson(this.config?.label), this.path, mode));
    // }
    paramFragenblocks && this.fragenblocks.push(...paramFragenblocks);

    this.frages = json.frages?.sort(sortArrayPosition)?.map((el: any) => new Frage(el, this.path, mode));

    // this.setupFragenblockMinimumInstances().then();
  }

  public setupDetectors() {
    this.frages?.forEach((el) => {
      el.setupComputedValues();
      if (el.berechnunglogik ) {
        el.setBerechnungRelatedFields();
      }
    });
    this.fragenblocks?.forEach((el) => el.setupDetectors());
  }

  public getTitle = (lang?: string) => {
    return getLocalization(this.titel, lang);
  };

  public findFrage(identifier: String): Frage | undefined {
    // let frage: Frage | undefined;
    const selectedFrage = this.frages?.find((el) => {
      return el.identifier === identifier;
    });
    // console.log('selectedFrage', selectedFrage);

    return selectedFrage;
  }

  public findFrageByRelativePath(ba: Bestandsaufnahme, path: String): Frage | undefined {
    return useIdentifierFrageFinder(ba, this.path + '.' + path);
  }

  public findFragenblockByRelativePath(ba: Bestandsaufnahme, path: String): Fragenblock | undefined {
    return useIdentifierFragenblockFinder(ba, this.path + '.' + path);
  }
  
  /**
   * Get the local progress of this fragenblock. Does not include other fragenblocks but only frages.
   */
  public getLocalProgress(): {
    progress: number;
    itemLength: number;
    debugFragesDetails?: any;
    debugValues?: any;
  } {
    if (!this.frages || this.frages.length === 0 || this.config?.isTemplate) {
      return { progress: 0, itemLength: 0 };
    }

    let itemsCompleted = 0;
    let itemsTotalRequired = 0;
    let itemsTotalOptional = 0;
    const debugFrageProgress: any[] = [];

    for (let key = 0; key < this.frages.length; key += 1) {
      const frage: Frage = this.frages[key];

      const prog = frage.getProgress();
      if (prog !== undefined) {
        itemsTotalRequired += 1;
        itemsCompleted += prog;
      } else {
        itemsTotalOptional += 1;
      }

      debugFrageProgress.push({
        name: frage.getTitle(),
        isValid: frage.isValid(),
        isRequired: frage.isRequired(),
        isShown: frage.isShown(),
      });
    }

    /**
     * Debug
     * console.log("getLocalProgress", this.path, debugFrageProgress, itemsTotalRequired, itemsCompleted);
     */

    let progress = -1;
    if (itemsTotalRequired > 0) {
      progress = Math.round((100 / itemsTotalRequired) * itemsCompleted);
    } else {
      progress = 100;
    }
    return {
      progress,
      itemLength: itemsTotalRequired,
      debugFragesDetails: debugFrageProgress,
      debugValues: {
        itemsTotalRequired,
        itemsTotalOptional,
      },
    };
  }

  /**
   * Returns
   * 1-100 if there is some progress
   * 0 if there is no progress but fragenblock contains required fields
   * undefined if fragenblock only contains optional fields
   */
  public getProgress(): number | undefined {
    if (this.config && this.config.isTemplate) {
      return undefined;
    }

    // progress is calculcated before progress indicator component is rendered
    // but: setup show detectors are only called after the component is rendered
    // for this reason progress indicator shows 0% progress when it is actually higher
    // TODO: following lines can prevent this issue, but cause to many recalculations and performance issues for intricate templates
    // if (!this.isShowDetectorSetup) { 
    //   this.setupShowDetector();
    // }

    let optionalOnly = true;
    this.frages?.forEach((frage) => {
      if (!frage.isAlwaysOptional()) {
        optionalOnly = false;
      }
    });

    const shouldCountBlock = (el: Fragenblock) => !el.config?.isTemplate && el.isShown() && ((el.fragenblocks && el.fragenblocks.length > 0) || (el.frages && el.frages?.length > 0));

    let blockProgressSum = 0;
    const blocksAmount = this.fragenblocks?.filter((el) => shouldCountBlock(el) && el.getProgress() !== undefined).length || 0;
    const blockProgress: any[] = [];

    this.fragenblocks?.forEach((el) => {
      const prog = el.getProgress();
      const shouldCount = shouldCountBlock(el);
      if (optionalOnly && prog !== undefined) {
        optionalOnly = false;
      }

      // this.identifier === 'allgemein-group' && console.log(this.identifier, el.identifier, prog, shouldCount)

      if (shouldCount && prog !== undefined) {
        blockProgressSum += prog;
        blockProgress.push({ name: el.getTitle(), progress: prog });
      }
    });

    if (optionalOnly) {
      return undefined;
    }

    const fragenProgress = this.getLocalProgress();

    const progressSum = blockProgressSum + fragenProgress.progress * fragenProgress.itemLength;
    const progressItems = blocksAmount + fragenProgress.itemLength;

    if (progressItems === 0) {
      return 0;
    }

    const progress = Math.round(progressSum / progressItems);

    /*
         * keep this log for debugging
        this.identifier === 'heizzentrale-tab' && console.log(this.identifier, progress, fragenProgress, blockProgressSum, blockProgress)
         */

    return progress;
  }

  /**
   * Only if the form is valid we will be able to save and close the current modal.
   */
  public isFormValid(): boolean {
    if (this.config?.isTemplate) {
      return true;
    }

    if (!this.isShown()) { return true; }

    let isFormValid = true;
    this.fragenblocks?.forEach((el) => {
      if (!el.isFormValid()) {
        isFormValid = false;
      }
    });
    if (!isFormValid) {
      return false;
    }

    if (!this.frages) {
      return false;
    }
    for (let key = 0; key < this.frages.length; key += 1) {
      const frage: Frage = this.frages[key];
      if (!frage.isValid( true )) {
        // console.log('isFormValid - invalid field:', frage.titel, frage.identifier, frage.toClassJson());
        return false;
      }
    }
    return true;
  }

  public setDirty(): void {
    this.isDirty = true;
  }
  /**
   * Any local changes made within the modal?
   */
  public isFormDirty(): boolean {
    if (this.isDirty) {
      return true;
    }

    let dirtyFragenBlock = false;
    this.fragenblocks?.forEach((el) => {
      if (el.isFormDirty()) {
        dirtyFragenBlock = true;
      }
    });
    if (dirtyFragenBlock) {
      return true;
    }

    if (this.frages) {
      for (let key = 0; key < this.frages.length; key += 1) {
        const frage: Frage = this.frages[key];
        if (frage.isDirty) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Called before saving the form so unset unshown data.
   *
   * Use case: "Gutachter" edits "Heizzentrale Abluft" and sets the field vorhanden = true.
   * After this, other fields will show up. If he then sets the field schadhaft = true a new Mangel will be created.
   * If he then sets vorhanden to false (again), schadhaft would still be set to true without this cleanup
   */
  public cleanUnshownData(): void {
    if (!this.frages) {
      return;
    }

    for (let key = 0; key < this.frages.length; key += 1) {
      const frage: Frage = this.frages[key];
      if (!frage.isShown()) {
        frage.cleanInput();
      }
    }
  }

  /**
   * Show the user a feedback which fields are currently preventing submitting the form.
   */
  public getCurrentInvalidFields() {
    const invalidFields = [];

    if (!this.frages) {
      return;
    }

    for (let key = 0; key < this.frages.length; key += 1) {
      const frage: Frage = this.frages[key];

      if (!frage.isValid()) {
        invalidFields.push(key);
      }
    }
    return invalidFields;
  }

  /**
   * User tried to submit the form, but some fields are missing. Setting this.showErrors to true, the form will give
   * the user hints.
   */
  public setShowErrors(): void {
    // console.error('Current invalid fields: ', this.getCurrentInvalidFields());
    // logger.info('Invalid form fields', {
    //     invalidFields: this.getCurrentInvalidFields(),
    //     fields: this.fields,
    // });
    console.log('FRAGENBLOCK SET ERROR');
    this.showErrors = true;
    this.fragenblocks?.forEach((el: any) => el.setShowErrors());
    this.frages?.forEach((el: any) => el.setShowErrors());
  }

  public getFiredMaengel(settings?: FiredMaengelFilterSettings): Mangelzuordnung[] {
    const maengel: Mangelzuordnung[] = [];
    this.fragenblocks?.forEach((el) => maengel.push(...el.getFiredMaengel(settings)));

    if (!settings || (settings.freieMangelsOnly && !settings.allRelevantMangels)) {
      const fm = settings?.relevantMangelsOnly ? this.freieMangels?.filter((el) => el.eingabeRelevant !== false) : this.freieMangels;
      fm && maengel.push(...fm);
    }

    if (!settings || (!settings.freieMangelsOnly && !settings.allRelevantMangels)) {
      this.frages?.forEach((frage) => {
        let frageMaengel = frage.getFiredMaengel(settings);
        frageMaengel = settings?.relevantMangelsOnly ? frageMaengel?.filter((el) => el.eingabeRelevant !== false) : frageMaengel;

        if (frageMaengel) {
          maengel.push(...frageMaengel);
        }
      });
    }

    if (settings?.allRelevantMangels) {
      this.frages?.forEach((frage) => {
        let frageMaengel = frage.getFiredMaengel(settings);
        frageMaengel = settings?.allRelevantMangels ? frageMaengel?.filter((el) => el.eingabeRelevant !== false) : frageMaengel;

        if (frageMaengel) {
          maengel.push(...frageMaengel);
        }
      });
      const fm = settings?.allRelevantMangels ? this.freieMangels?.filter((el) => el.eingabeRelevant !== false) : this.freieMangels;
      fm && maengel.push(...fm);
    }

    return maengel;
  }

  public maengelReport() {
    return new MaengelReport(this.getFiredMaengel({ allRelevantMangels: true }));
  }

  public getFrages(): Frage[] {
    const frages: Frage[] = [];
    this.fragenblocks?.forEach((el) => frages.push(...el.getFrages()));
    this.frages && frages.push(...this.frages);
    return frages;
  }

  public errors() {
    if (!this.showErrors) {
      return [];
    }

    // @ts-ignore
    const { t } = i18n.global;

    const errors = [];
    if (!this.isFormValid()) {
      errors.push({
        // @ts-ignore
        $message: `${this.getTitle()} ` + t('hzba.wirdBenoetigtInfo'),
      });
    }
    return errors;
  }

  public submit() {
    this.fragenblocks?.forEach((el: any) => el.submit());
    this.frages?.forEach((el: Frage) => el.submit());
  }

  /**************************************
   *
   *
   * Anzeigelogik Methods
   *
   *
   *************************************/

  /**
   * Find all Elements referenced by the identifier to keep track on those variables when they have changed.
   *
   * For some reason, when this function is called within a .ts file, the store won't be initialized. That's why
   * we don't call it in the constructor, but quite similar after setup within a .vue file.
   */
  public setupShowDetector(): void {
    this.fragenblocks?.forEach((el) => el.setupShowDetector());
    this.frages?.forEach((el) => el.setupShowDetector());
    if (!this.anzeigelogik || isEmptyObject(this.anzeigelogik)) {
      this.anzeigen = true;
      return;
    }

    this.anzeigelogik.setupFireDetector(this.path, this.mode);
    this.isShowDetectorSetup = true;
  }

  /**
   * Is this Fragenblock shown?
   * "setupShowDetector" needs to be called in advance.
   */
  public isShown(): boolean | undefined {
    if (!this.isShowDetectorSetup) {
      return this.anzeigen;
    }
    if (!this.anzeigelogik || isEmptyObject(this.anzeigelogik)) {
      this.anzeigen = true;
      return true;
    }

    this.anzeigen = this.anzeigelogik.isFired();
    return this.anzeigen;
  }

  /**************************************
   *
   *
   * Unlocklogik Methods
   *
   *
   *************************************/

  /**
   * Find all Elements referenced by the identifier to keep track on those variables when they have changed.
   *
   * For some reason, when this function is called within a .ts file, the store won't be initialized. That's why
   * we don't call it in the constructor, but quite similar after setup within a .vue file.
   */
  public setupUnlockDetector(): void {
    this.fragenblocks?.forEach((el) => el.setupUnlockDetector());

    if (!this.freischaltunglogik || isEmptyObject(this.freischaltunglogik)) {
      this.unlocked = true;
      return;
    }

    this.freischaltunglogik.setupFireDetector(this.path, this.mode);
    this.isUnlockDetectorSetup = true;
  }

  /**
   * Is this Fragenblock unlocked?
   * "setupShowDetector" needs to be called in advance.
   */
  public isUnlocked(): boolean | undefined {
    if (!this.isUnlockDetectorSetup) {
      return this.unlocked;
    }
    if (!this.freischaltunglogik || isEmptyObject(this.freischaltunglogik)) {
      return true;
    }

    return this.freischaltunglogik.isFired();
  }

  /**************************************
   *
   *
   * 1:N Multiple instance Methods
   *
   *
   *************************************/

  /**
   * Checks whether this current Fragenblock is an instance or just a template of an 1:N relationship.
   */
  public isInstanceOfMultiple(): boolean {
    return this.blockTyp?.toLowerCase() === 'mehrfach' && !this.config?.isTemplate;
  }

  /**
   * Get all instances of a specific component like "Heizkreis", "Wärmeerzeuger" etc.
   * @param identifier
   */
  public getMultipleFragenblockInstances(identifier: string): Fragenblock[] | undefined {
    return this.fragenblocks?.filter((el) => el.identifier === identifier && el.isInstanceOfMultiple());
  }

  /**
   * Setup the minimum amount of instances for all fragenblock templates.
   */
  public async setupFragenblockMinimumInstances() {
    this.fragenblocks?.forEach((el) => el.setupFragenblockMinimumInstances());

    const templateFragenblocks = this.fragenblocks?.filter((el) => el.config?.isTemplate);
    if (!templateFragenblocks) {
      return;
    }

    const promises = [];
    
    for (let x = 0; x < templateFragenblocks.length; x++) {
      const el = templateFragenblocks[x];
      const instances = this.getMultipleFragenblockInstances(el.identifier);
      const length = (instances && instances.length) || 0;
      const minLength = el.config?.minCount || 0;
      const toAdd = minLength - length;

      // console.log("setupFragenblockMinimum", el.identifier, minLength, 'length:', length, 'toAdd:', toAdd, instances, this.fragenblocks, this.getMultipleFragenblockInstances(el.identifier) )

      if (toAdd > 0) {
        for (let i = 0; i < toAdd; i++) {
          promises.push(this.addFragenblockInstance(el));
        }
      }
    }
    await Promise.all(promises);
  }

  public async checkAllowOneMoreInstance(fragenblockTemplate: Fragenblock): Promise<boolean> {
    const existingInstances = this.getMultipleFragenblockInstances(fragenblockTemplate.identifier);
    const instanceLength = (existingInstances && existingInstances.length) || 0;
    const maxInstanceLength = fragenblockTemplate.config?.maxCount;

    if (maxInstanceLength && instanceLength >= maxInstanceLength) {
      // const { t } = i18n.global; // todo use i18n here
      const alert = useAlert();
      await alert.show('Maximale Anzahl erreicht', `Es sind maximal ${maxInstanceLength} Fragenblöcke für "${fragenblockTemplate.getTitle()}" erlaubt.`);
      return false;
    }
    return true;
  }

  public async checkAllowDeleteInstance(fragenblockTemplate: Fragenblock): Promise<boolean> {
    const existingInstances = this.getMultipleFragenblockInstances(fragenblockTemplate.identifier);
    const instanceLength = (existingInstances && existingInstances.length) || 0;
    const minInstanceLength = fragenblockTemplate.config?.minCount || 0;
    if (instanceLength <= minInstanceLength) {
      const { t } = i18n.global; // todo use i18n here
      const alert = useAlert();
      await alert.show('Minimale Anzahl erreicht.', `Es werden mindestens ${minInstanceLength} Fragenblöcke für "${fragenblockTemplate.getTitle()}" benötigt.`);
      return false;
    }
    return true;
  }

  /**
   * Creates a new multi-instance by coping a template and adds a new instance to it's children.
   * @param fragenblockTemplate is a child of the current Fragenblock with the property isTemplate set to true.
   * @return true if it succeeded, false if the maximum amount of instances was reached
   */
  public async addFragenblockInstance(fragenblockTemplate: Fragenblock) {
    if (!this.fragenblocks) {
      console.error('addFragenblockInstance failed because fragenblocks is not setup.');
      return false;
    }

    if (!(await this.checkAllowOneMoreInstance(fragenblockTemplate))) {
      console.log('No more instances allowed.');
      return false;
    }

    const newFragenblock: FragenblockJson = await fragenblockTemplate.cleanAndCopyJson();
    if (newFragenblock.config) {
      newFragenblock.config.isTemplate = undefined;
    }
    const instance = new Fragenblock(newFragenblock, this.path, this.mode);

    const adjustArrayPositions = () => {
      let instancePosition = fragenblockTemplate.arrayPosition;
      this.fragenblocks.forEach((el) => {
        if (el.identifier === fragenblockTemplate.identifier) {
          instancePosition = el.arrayPosition + 1;
        }
      });
      instance.arrayPosition = instancePosition;

      this.fragenblocks.forEach((el) => {
        if (el.arrayPosition >= instancePosition) {
          el.arrayPosition += 1;
        }
      });
    };
    adjustArrayPositions();

    this.fragenblocks.push(instance);
    this.fragenblocks.sort(sortArrayPosition);
    instance.setupShowDetector();
    instance.setupUnlockDetector();

    this.setDirty();

    return true;
  }

  public async deleteFragenblockInstance(fragenblock: Fragenblock) {
    if (!(await this.checkAllowDeleteInstance(fragenblock))) {
      return false;
    }

    const i = this.fragenblocks?.findIndex((el) => el.uid === fragenblock.uid);
    if (typeof i === 'number') {
      this.setDirty();

      const el = this.fragenblocks[i];

      this.fragenblocks?.splice(i, 1);

      const instancePosition = el.arrayPosition;
      this.fragenblocks.forEach((el) => {
        if (el.arrayPosition >= instancePosition) {
          el.arrayPosition -= 1;
        }
      });
    } else {
      console.error('Error deleting instance');
    }
  }

  public async duplicateFragenblockInstance(fragenblock: Fragenblock) {
    if (!(await this.checkAllowOneMoreInstance(fragenblock))) {
      return false;
    }

    const newFragenblock = await fragenblock.cleanAndCopyJson();
    this.setDirty();
    const dup = new Fragenblock(newFragenblock, this.path, fragenblock.mode);

    const adjustArrayPositions = () => {
      let instancePosition = fragenblock.arrayPosition;
      this.fragenblocks.forEach((el) => {
        if (el.identifier === fragenblock.identifier) {
          instancePosition = el.arrayPosition + 1;
        }
      });
      dup.arrayPosition = instancePosition;

      this.fragenblocks.forEach((el) => {
        if (el.arrayPosition >= instancePosition) {
          el.arrayPosition += 1;
        }
      });
    };
    adjustArrayPositions();

    this.fragenblocks?.push(dup);
    this.fragenblocks.sort(sortArrayPosition);
    dup.setupShowDetector();
    dup.setupUnlockDetector();
    return true;
  }

  /**************************************
   *
   *
   * PDF & Data evaluation Methods
   *
   *
   *************************************/

  public toPdfDataRow(lang: string, description1?: string, titleSuffix?: string): ExtendedMSERow {
    const maengelReport = this.maengelReport();

    let title = this.getTitle(lang);
    if (titleSuffix) {
      title += titleSuffix;
    }

    return {
      title: title,
      ...maengelReport.getMSESummary(),
      description1: description1,
      description2: this.maengelReport().maengel?.length,
    };
  }

  public toPdfDataRows(lang: string, description1?: string): ExtendedMSERow[] {
    const fragenblocks = this.fragenblocks?.filter((el) => !el.config.isTemplate);
    return (
      fragenblocks?.map((fragenblock) => {
        return fragenblock.toPdfDataRow(lang, description1);
      }) || []
    );
  }

  /**
   * If this instance is an instance of a multi instance, this function returns the index position based on parentFragenblock
   */
  public getIndexPosition(parentFragenblock: Fragenblock) {
    return (this.blockTyp && this.blockTyp.toLowerCase() === 'mehrfach' && `${parentFragenblock.fragenblocks.filter((el) => el.identifier === this.identifier).indexOf(this)}`) || '';
  }

  /**************************************
   *
   *
   * Copy, Print, Offline Methods
   *
   *
   *************************************/

  public toJson() {
    return { hint: 'Please call toClassJson. toJson() would cause a crash on ios/android.' };
  }

  /**
   * Recursive Method to get the json of every subobject. Does not necessarily deep copy the whole object
   * as we still have nested objects here. Use copyJson() for deep copy.
   *
   * We do not copy variables that are used for state management,
   * We only copy those who are relevant for the backend.
   */
  public async toClassJson(settings: ToJsonSettings = {}): Promise<FragenblockJson> {
    const fragesJson = this.frages && (await Promise.all(this.frages.map(async (el: Frage) => (settings.cleanup ? await el.cleanAndCopyJson() : await el.copyJson(settings)))));

    const labelFragenblock = this.fragenblocks?.find((el: any) => el.identifier === labelFragenblockJson().identifier);
    const label = labelFragenblock?.frages && labelFragenblock.frages[0]?.getCurrentInput()?.toString();
    if (label) {
      if (!this.config) {
        this.config = {};
      }
      this.config.label = label;
    }

    const fragenblockJson = await Promise.all(
      this.fragenblocks
        ?.filter((el: any) => el.identifier !== labelFragenblockJson().identifier)
        .map(async (el: Fragenblock) => (settings.cleanup ? await el.cleanAndCopyJson() : await el.copyJson(settings))),
    );

    const freieMangels = this.freieMangels && (await Promise.all(this.freieMangels.map(async (el) => (settings.cleanup ? await el.cleanAndCopyJson() : await el.copyJson(settings)))));

    return {
      id: settings.cleanup ? undefined : this.id,
      titel: this.titel,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      locale: this.locale,
      blockTyp: this.blockTyp,
      config: this.config,
      uid: settings.cleanup ? undefined : this.uid,
      identifier: this.identifier,
      frages: fragesJson,
      anzeigelogik: settings.cleanup ? this.anzeigelogik?.cleanAndCopyJson() : this.anzeigelogik?.copyJson(),
      freischaltunglogik: settings.cleanup ? this.freischaltunglogik?.cleanAndCopyJson() : this.freischaltunglogik?.copyJson(),
      arrayPosition: this.arrayPosition,
      anzeigen: this.anzeigen,
      fragenblocks: fragenblockJson,
      freieMangels: freieMangels,
      geoJson: this.geoJson
    };
  }

  public async toLeanJson(settings: ToJsonSettings = {}): Promise<FragenblockJson> {
    const fragesJson = this.frages && (await Promise.all(this.frages.map(async (el: Frage) => await el.copyLeanJson(settings))));

    const labelFragenblock = this.fragenblocks?.find((el: any) => el.identifier === labelFragenblockJson().identifier);
    const label = labelFragenblock?.frages && labelFragenblock.frages[0]?.getCurrentInput()?.toString();
    if (label) {
      if (!this.config) {
        this.config = {};
      }
      this.config.label = label;
    }

    const fragenblockJson =
      this.fragenblocks &&
      (await Promise.all(this.fragenblocks?.filter((el: any) => el.identifier !== labelFragenblockJson().identifier).map(async (el: Fragenblock) => await el.copyLeanJson(settings))));

    const freieMangels = this.freieMangels && (await Promise.all(this.freieMangels.map(async (el) => await el.copyLeanJson(settings))));

    return {
      id: this.id,
      titel: this.titel,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      locale: this.locale,
      blockTyp: this.blockTyp,
      arrayPosition: this.arrayPosition,
      config: this.config,
      uid: this.uid,
      identifier: this.identifier,
      frages: fragesJson,
      anzeigen: this.anzeigen,
      fragenblocks: fragenblockJson,
      freieMangels: freieMangels,
      geoJson: this.geoJson
    };
  }

  /**
   * Deep copy this class with all of it's subobjects.
   */
  public async copyJson(settings: ToJsonSettings = {}): Promise<FragenblockJson> {
    return JSON.parse(JSON.stringify(await this.toClassJson(settings)));
  }

  public async copyLeanJson(settings: ToJsonSettings = {}): Promise<FragenblockJson> {
    if (this.id) {
      return JSON.parse(JSON.stringify(await this.toLeanJson(settings)));
    } else {
      // If it's a new instance we should upload all fields
      return JSON.parse(JSON.stringify(await this.toClassJson(settings)));
    }
  }

  /**
   * Deep copy this class with all of it's subobjects.
   * Use this if you want an object that is like the original
   * but without specific id's that makes the original instance unique (e.g. copy from template, duplicate)
   */
  public async cleanAndCopyJson(): Promise<FragenblockJson> {
    return JSON.parse(JSON.stringify(await this.toClassJson({ cleanup: true })));
  }

  /**
   * toString can be used to console log the json of this object.
   */
  public async toString(settings: ToJsonSettings = {}): Promise<string> {
    return JSON.stringify(await this.toClassJson(settings));
  }

  public filterPhotos(filterFctn: Function): APhoto[] {
    const photos: APhoto[] = [];
    this.fragenblocks?.forEach((el) => photos.push(...el.filterPhotos(filterFctn)));
    this.frages?.forEach((el) => photos.push(...el.filterPhotos(filterFctn)));
    this.freieMangels?.forEach((el) => photos.push(...el.filterPhotos(filterFctn)));
    return photos;
  }
}
