<template>
  <div 
    :class="imageClass"
  >
    <div :class="imageClass">
      <!-- <a 
        :href="url"
        @click="(event) => {
          event.preventDefault();
          openBaseModal('gallery.pdf', {
            pdfs: file,
            title: file.title,
            downloadEnabled: true,
          })}"
      > -->
      <a 
        :href="url"
        @click="(event) => {
          event.preventDefault()
        }"
      >

        <canvas 
          :id="id" 
          :class="imageClass"
          :url="url"
          :style="setToMax? 'height: 100%; width: 100%' : 'height: 200; width: 300'"
          data-cy="immobilie-dokument-canvas"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { onMounted} from "vue";
import "pdfjs-dist/web/pdf_viewer.css";
const pdfjsLib = require("pdfjs-dist/build/pdf");
import { openBaseModal } from "@/utilities/modal-helper";

// const pdfjsLib = require("pdfjs-dist");
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import BaseModal from './BaseModal.vue';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default {
  name: 'PdfThumbnail',
  components: { BaseModal },
  props: {
    imageClass: {
      type: String,
      default: 'w-full h-auto'
    },
    url: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: undefined
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 200,
    },
    id: {
      type: String,
      default: undefined
    },
    setToMax: {
      type: Boolean,
      default: false
    }

  },
  setup(props) {
    const pdfThumbnail = async () => {

      pdfjsLib.getDocument(props.url).promise.then(async function(pdf) {

        const page = await pdf.getPage(1);
        const canvas = document.getElementById(props.id);
        if (canvas) {
          let viewport;
        if (props.setToMax) {
          viewport = page.getViewport({scale: 0.1});
          viewport = page.getViewport({scale: props.height / viewport.height});
          canvas.height = viewport.height;
          canvas.width = viewport.width;
        }
        else {
          viewport = page.getViewport({scale: 1});
          viewport = page.getViewport({scale: props.width / viewport.width});
          // canvas.width = 300;
          canvas.width = viewport.width;
          canvas.height = 200;
        }
        const context = canvas.getContext('2d')
        page.render({ canvasContext: context, viewport: viewport })
        // isLoaded.value = true;
        // numPages.value = pdf.numPages;
        //pdfViewer.setDocument(pdf);

        }
      }).catch((error) => {
        console.error("Error fetching PDF URL", error);
      });
    }

    onMounted(() => {
      pdfThumbnail();
    })

    return {
      openBaseModal,
    }
  }
}
</script>

<style>
</style>