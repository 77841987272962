<template>
  <ion-page>
    <toolbar :title="baSubpage === 'summary' ? 'Vorschau' :t(`${organisationPrefix}toolbar.mobile.surveyDetail`) + ` - ${immobilie ? immobilie.externeObjektNr : '...'}`">
      <template #leading>
        <ion-buttons slot="start">
          <!--          <ion-back-button-->
          <!--            v-if="baSubpage !== 'summary'"-->
          <!--            default-href="/bas"-->
          <!--          />-->
          <!--          <ion-button-->
          <!--            v-if="baSubpage !== 'summary'"-->
          <!--            fill="clear"-->
          <!--            color="light"-->
          <!--            class="px-0 -ml-4"-->
          <!--            size="small"-->
          <!--            @click="() => closeHzba()"-->
          <!--          >-->
          <!--            <ion-icon-->
          <!--              :icon="close"-->
          <!--              size="large"-->
          <!--            />-->
          <!--          </ion-button>-->
          
          <ion-button
            fill="clear"
            class="px-0 -ml-4"
            size="small"
            @click="() => goBackFromSummary()"
          >
            <ion-icon
              :icon="arrowBack"
              size="large"
            />
          </ion-button>
          <!-- icon "no network connection" -->
          <ion-button
            v-if="networkStatus === ('none' || 'undefined') && baSubpage !== 'summary'"
            :key="networkStatus"
            data-cy="network-status"
          >
            <img src="/assets/img/svg/Cloud_Error.svg">
          </ion-button>
        </ion-buttons>
      </template>
      <template #trailing>
        <ion-buttons
          slot="primary"
        >
          <!-- icon "no network connection" -->
          <ion-button
            v-if="networkStatus === ('none' || 'undefined') && baSubpage === 'summary'"
            :key="networkStatus"
            class="ml-white-text"
            data-cy="network-status"
          >
            <img src="/assets/img/svg/Cloud_Error.svg">
          </ion-button>

          <ion-button
            v-if="baSubpage !== 'summary'"
            class="ml-white-text"
            data-cy="button-preview"
            @click="showSummary"
          >
            {{ $t("hzba.buttons.vorschau") }}
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>

    <ion-content>
      <div v-if="ba">
        <div
          class="md:container lg:flex md:mx-auto mt-6"
          :style="`padding-bottom: calc(${slidingData.deviceHeight}px - ${slidingData.slidingMapPosition}px)`"
        >
          <div
            class="overview-item row lg:block items-center lg:w-1/3 lg-boxed-container lg:p-4 self-start z-50 top-0 lg:top-4"
          >
            <div class="mb-8 hidden lg:block">
              <h3 class="mt-6">
                <strong>{{ $t("hzba.uebersicht.objektTitel") }} {{ immobilie && immobilie.externeObjektNr }}</strong><br>{{ getLocalization(ba.name) }}
              </h3>

              <!-- Begehungsdatum -->
              <p
                class="flex my-0"
                :class="isUpdatingBegehungsDate ? 'items-start' : 'items-center'"
              >
                <span class="mr-2 sm:mb-0 inline sm:block">{{ $t("hzba.datum.begehung") }}: </span>
                <date-change 
                  v-if="isUpdatingBegehungsDate"
                  v-model="changedBegehungsdate"
                  :loading="isPerformingDatabaseUpdate"
                  @confirm="saveBegehungsDate()"
                  @cancel="isUpdatingBegehungsDate = false"
                />
                <span v-else>{{ begehungsDateLocalizedString }}</span>
                <ion-button
                  v-if="canUpdateBegehungsDate && !isUpdatingBegehungsDate"
                  class="ml-1 my-0 pb-0.5"
                  size="small"
                  fill="clear"
                  :title="$t('hzba.buttons.aendern')"
                  @click="isUpdatingBegehungsDate = true"
                >
                  <ion-icon
                    slot="icon-only"
                    size="small"
                    color="ternary"
                    :icon="editIcon"
                  />
                </ion-button>
              </p>

              <!-- Sichtungsdatum -->
              <p
                v-if="featureFlags?.survey?.showSichtungsdatum"
                class="flex"
                :class="[
                  isUpdatingSichtungsDate ? 'items-start' : 'items-center',
                  canUpdateBegehungsDate ? 'my-2' : 'my-3'
                ]"
              >
                <span class="mr-2 sm:mb-0 inline sm:block">{{ $t("hzba.datum.sichtung") }}: </span>
                <date-change 
                  v-if="isUpdatingSichtungsDate"
                  v-model="changedSichtungsdate"
                  :loading="isPerformingDatabaseUpdate"
                  @confirm="saveSichtungsDate()"
                  @cancel="isUpdatingSichtungsDate = false"
                />
                <span v-else>{{ sichtungsDateLocalizedString }}</span>

                <ion-button
                  v-if="canUpdateSichtungsDate && !isUpdatingSichtungsDate"
                  class="ml-1 my-0 pb-0.5"
                  size="small"
                  fill="clear"
                  :title="$t('hzba.buttons.aendern')"
                  @click="isUpdatingSichtungsDate = true"
                >
                  <ion-icon
                    slot="icon-only"
                    size="small"
                    color="ternary"
                    :icon="editIcon"
                  />
                </ion-button>
              </p>

              <!-- Last update -->
              <p 
                v-if="ba.updatedAt" 
                class="mt-3"
              >
                {{ $t("hzba.uebersicht.updateTitel") }}: {{ new Date(ba.updatedAt).toLocaleString("de-De") }}
              </p>
              <p v-if="ba.updatedBy?.username">
                {{ $t("hzba.uebersicht.updateVonTitel") }} {{ ba.updatedBy?.username }}
              </p>
              <p v-if="HzbaStatus[ba.status]">
                {{ $t("hzba.uebersicht.statusTitel") }}: {{ translatedHzbaStatus(ba.status, t) }} {{ ba.bearbeitenderNutzer?.username ? `(${ba.bearbeitenderNutzer.username})` : '' }}
              </p>
              <AButton
                v-if="featureFlags?.survey?.changeStatus"
                :btn-tertiary="true"
                data-cy="hzba-status"
                @click="openChangeStatusAlert(ba, t)"
              >
                {{ t('hzba.updateStatus') }}
              </AButton>
              <p v-if="ba.isLocal">
                {{ $t("hzba.uebersicht.asynchron") }}
              </p>
            </div>

            <tabs
              :selected-view="baSubpage"
              @showSummary="showSummary"
              @changeTab="changeTab"
            />
          </div>

          <div
            class="lg:w-2/3"
          >
            <div class="pb-8 container">
              <socket-disconnected-hint class="mt-4 mr-4 ml-4">
                {{ t(`${organisationPrefix}hzba.noSocketConnection`) }}
              </socket-disconnected-hint>

              <HzbaMainView
                v-if="ba && baSubpage !== 'summary'"
                :ba="ba"
                :immobilie="immobilie"
                :route-name="baSubpage"
              />
              <HzbaSummary v-else-if="baSubpage === 'summary'" />
            </div>
          </div>
        </div>
      </div>
      <Skeleton v-else />
    </ion-content>
    <div
      v-if="ba"
      id="slidingMapContent"
      class="mapContent"
      :style="[`top: ${slidingData.slidingMapPosition}px`, `height: calc(${slidingData.deviceHeight}px - ${slidingData.slidingMapPosition}px)`]"
    >
      <div
        id="slidingMapHeader"
        ref="slidingMapHeaderRef"
        class="listHeader"
      >
        <div class="mapSwipeIndicator" />
        <div class="mapHeaderTitle">
          {{ $t('hzba.slidingMap.headerDescription') }}
        </div>
        <div
          v-if="slidingData.isCalculated"
          class="mapHeaderClose"
          @click="toggleSlidingMap"
        >
          <ion-icon
            class="blackIcon mapCloseIcon"
            :icon="close"
          />
        </div>
      </div>
      <AppMap
        v-if="featureFlags?.surveyMap && ba"
        style="height: 100%"
        :identifier="'surveyMap'"
        :ba="ba"
        :map-settings="currentProject.mapConfig?.surveyMap"
      />
    </div>
    <AppFloatingIcon
      v-if="featureFlags?.surveyMap && ba"
      :is-visible="isMapIconVisible"
      @onClick="toggleSlidingMap"
    >
      <ion-icon
        class="blackIcon"
        :icon="mapOutline"
      />
    </AppFloatingIcon>
  </ion-page>
</template>

<script lang="ts">

import AButton from "@/components/Base/AButton";
import DateChange from "@/components/Base/DateChange.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import SocketDisconnectedHint from "@/components/Other/SocketDisconnectedHint.vue";
import ProgressIndicator from "@/components/ProgressIndicator";
import Skeleton from "@/components/Skeleton.vue";
import Tabs from "@/components/hzba/BaTabs.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaSummary from "@/components/hzba/Views/BaSummary.vue";
import HzbaMainView from "@/components/hzba/Views/HzbaMainView";
import AppFloatingIcon from '@/components/v2/App/AppFloatingIcon.vue';
import AppMap from '@/components/v2/App/AppMap.vue';
import useBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSubpage";
import useDateChangeUtils from "@/composables/Bestandsaufnahme/useDateChangeUtils";
import useToasts from "@/composables/useToasts";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { HzbaStatus, translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import User from "@/models/user";
import PdfPreview from "@/pages/hzba/_id/PdfPreview";
import getLocalization from "@/utilities/get-localization";
import { Network } from "@capacitor/network";
import {
IonBackButton,
IonButton,
IonButtons,
IonContent,
IonDatetime,
IonDatetimeButton,
IonIcon,
IonLabel,
IonPage,
IonPopover,
IonSegment,
IonSegmentButton,
createGesture,
onIonViewWillEnter,
onIonViewWillLeave
} from "@ionic/vue";
import { arrowBack, close, createOutline as editIcon, mapOutline } from "ionicons/icons";
import { ComputedRef, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ViewHZBA",
  components: {
    Skeleton,
    PdfPreview,
    HzbaMainView,
    HzbaSummary,
    Tabs,
    IonIcon,
    AButton,
    HzbaGroup,
    ProgressIndicator,
    Toolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonLabel,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonDatetime,
    IonDatetimeButton,
    IonPopover,
    DateChange,
    SocketDisconnectedHint,
    AppMap,
    AppFloatingIcon
  },
  setup(_, params) {
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });

    const { t, locale } = useI18n({ useScope: 'global' })
    const slidingMapHeaderRef = ref();
    const store = useStore();
    const { user, canUpdateBegehungsDate, canUpdateSichtungsDate } = useUser();
    const router = useRouter();
    const lastRoute = ref()
    const isMapIconVisible = ref(true)
    const slidingData = ref({
      deviceHeight: 0,
      slidingMapMaxPosition: 0,
      slidingMapHeaderHeight: 0,
      slidingMapPosition: 0,
      isCalculated: false
    })

    const toast = useToasts();

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);
    const isSocketConnected = computed(() => {
      return store.getters["app/isSocketConnected"];
    });
    const currentProject = computed(() => store.state.user.currentUserProject);

    const { ba, immobilie, baSubpageName: baSubpage, localSubpage, openChangeStatusAlert } = useBestandsaufnahmeSubpage({onIonViewWillEnter, onIonViewWillLeave });
    const { 
       isPerformingDatabaseUpdate,
       isUpdatingBegehungsDate,
       isUpdatingSichtungsDate,
       changedBegehungsdate,
       changedSichtungsdate,
       begehungsDateLocalizedString,
       sichtungsDateLocalizedString,
       saveBegehungsDate,
       saveSichtungsDate
      } = useDateChangeUtils(ba);

    const showSummary = () => {
      lastRoute.value = baSubpage.value;
      // router.replace(`/ba/${ba.value!.id}/summary`)
      history.replaceState({}, 'title', `/ba/${ba.value!.id}/summary`)
      localSubpage.value = 'summary';
    }

    const goBackFromSummary = () => {

      const rec = store.state.app.recentRoute;

      // console.log("goBackFromSummary", rec, baSubpage);
      if (baSubpage.value === "summary") {
        if (lastRoute.value) {
          changeTab(lastRoute.value)
          // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
        } else {
          changeTab('')
          // router.replace(`/ba/${ba.value!.id}`)
        }
      } else if (rec.name === 'bas') {
        router.go(-1);
      } else {
        router.replace("/bas");
      }
      // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
    }

    const changeTab = (val?: string) => {
      if (val) {
        history.replaceState({}, 'title', `/ba/${ba.value!.id}/${val}`)
      } else {
        history.replaceState({}, 'title', `/ba/${ba.value!.id}`)
      }

      localSubpage.value = val;
    }

    const networkStatus = ref();

    Network.addListener("networkStatusChange", (status) => {
      console.log('network status: ', status, 'connection type: ', status.connectionType);
      networkStatus.value = status.connectionType;
    });

    watch(isSocketConnected, (newVal, oldVal) => {
      if(newVal && oldVal === false) {
        // oldVal === null in initialization
        toast.socketConnected()
      }
    })
  
    const closeHzba = () => {
      router.replace(`/bas`)
    }

    watch(() => ba, () => {
        if(ba.value) {
          setTimeout(() => {
            if(ba?.value?.id) {
              // only execute if ba is still set
              enableSlidingMap();
            }
          }, 300)
        }
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(() => slidingData, () => {
        if(slidingData.value.slidingMapPosition === window.innerHeight) {
          isMapIconVisible.value = true
        } else {
          isMapIconVisible.value = false
        }
      },
      {
        deep: true,
        immediate: true
      }
    )

    function clamp (x: number, min: number, max: number) {
      return x < min ? min : x > max ? max : x;
    }

    function toggleSlidingMap(event: any) {
      if(slidingData.value.slidingMapPosition === window.innerHeight) {
        slidingData.value.slidingMapPosition = (slidingData.value.deviceHeight - (slidingData.value.slidingMapHeaderHeight + 300))
      } else {
        slidingData.value.slidingMapPosition = slidingData.value.deviceHeight
      }
    }

    function enableSlidingMap() {
      if(slidingData.value.isCalculated) return
      slidingData.value.deviceHeight = window.innerHeight
      slidingData.value.slidingMapHeaderHeight = slidingMapHeaderRef.value?.clientHeight
      slidingData.value.slidingMapPosition = slidingData.value.deviceHeight
      slidingData.value.slidingMapMaxPosition = (slidingData.value.deviceHeight - 60)
      slidingData.value.isCalculated = true

      slidingMap()
    }

    function slidingMap() {
      const element = document.getElementById('slidingMapHeader')!
      let startPosY = 0;
      if (!element) {
        console.error("Trying to initialize slidingMap but html is not currently rendered");
      }
      const gesture = createGesture({
        el: element,
        gestureName: 'slidingMap',
        direction: 'y',
        passive: false,

        onStart: (detail) => {
          startPosY = detail.currentY - (slidingData.value.deviceHeight - slidingData.value.slidingMapMaxPosition) - slidingData.value.slidingMapPosition
        },

        onMove: (detail) => {
          slidingData.value.slidingMapPosition = clamp(
            detail.currentY - (slidingData.value.deviceHeight - slidingData.value.slidingMapMaxPosition) - startPosY,
            slidingData.value.slidingMapHeaderHeight,
            slidingData.value.slidingMapMaxPosition
          );
        },

        onEnd: (detail) => {
          return
        }
      })
      gesture.enable(true)
    }

    return {
      showSummary,
      baSubpage,
      immobilie,
      objectCountHeight: 62,
      isReadonly,
      ba,
      HzbaStatus,
      closeHzba,
      arrowBack,
      mapOutline,
      close,
      editIcon,
      goBackFromSummary,
      translatedHzbaStatus,
      t,
      getLocalization,
      changeTab,
      networkStatus,
      organisationPrefix,
      canUpdateBegehungsDate,
      canUpdateSichtungsDate,
      featureFlags,
      locale,
      isPerformingDatabaseUpdate,
      isUpdatingBegehungsDate,
      isUpdatingSichtungsDate,
      changedBegehungsdate,
      changedSichtungsdate,
      begehungsDateLocalizedString,
      sichtungsDateLocalizedString,
      saveBegehungsDate,
      saveSichtungsDate,
      currentProject,
      slidingMapHeaderRef,
      slidingData,
      toggleSlidingMap,
      isMapIconVisible, 
      openChangeStatusAlert
    };
  }
});
</script>


<style scoped lang="scss">
.overview-item {
  position: sticky;
  align-self: flex-start !important;
  position: -webkit-sticky; /* for Safari */
}

.listHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: grab;
  position: relative;

  &::after {
    display: none;
  }

  .mapSwipeIndicator {
    width: 48px;
    height: 4px;
    background: var(--grey100);
    border-radius: 99px;
  }

  .mapHeaderTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: var(--navy800);
    margin-top: 8px;
  }

  .mapHeaderClose {
    display: flex;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-16px, -50%);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 8px;
  }
}

.mapContent {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.12);
  background: var(--white100);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ion-icon {
  color: var(--white100);

  &.blackIcon {
    color: var(--black100);
  }

  &.mapCloseIcon {
    font-size: 20px;
  }
}

</style>