import { putBestandsaufnahme } from "@/api/ModelApi";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import moment from "moment";
import { computed, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useToasts from "../useToasts";

export default function useDateChangeUtils(ba: Ref<Bestandsaufnahme | undefined>) {

    const isUpdatingBegehungsDate = ref(false); // set to true from outside when respective controls are activated
    const isUpdatingSichtungsDate = ref(false);

    const isPerformingDatabaseUpdate = ref(false);

    const initialBegehungsdate = ba.value?.begehungsdatum ? new Date(ba.value.begehungsdatum) : new Date(Date.now());
    const initialSichtungsdate = ba.value?.sichtungsdatum ? new Date(ba.value.sichtungsdatum) : new Date(Date.now());

    const changedBegehungsdate = ref(moment(initialBegehungsdate).format());
    const changedSichtungsdate = ref(moment(initialSichtungsdate).format());

    const store = useStore();
    const { updateErrorDate } = useToasts();
    const { t, locale } = useI18n();
    const currentProject = computed(() => store.state.user.currentUserProject);

    // to display in UI
    const begehungsDateLocalizedString = computed(() => {
        return ba.value?.begehungsdatum ? new Date(ba.value.begehungsdatum).toLocaleString(locale.value) : t("hzba.datum.noDateSet");
    });

    const sichtungsDateLocalizedString = computed(() => {
        return ba.value?.sichtungsdatum ? new Date(ba.value.sichtungsdatum).toLocaleString(locale.value) : t("hzba.datum.noDateSet");
    });

    const saveDateToDatabase = async (begehung: boolean) => {
        try {
            if (!ba.value) {
                throw new Error("No survey found to update date");
            }
            const isOnline = store.getters["app/isOnline"];
            let updatedBaJson = null;

            if (isOnline) {
                isPerformingDatabaseUpdate.value = true;
                const copiedBa = await ba.value.copyLeanJson({ prepareForSync: true });
                if (begehung) {
                    copiedBa.begehungsdatum = changedBegehungsdate.value;
                } else {
                    copiedBa.sichtungsdatum = changedSichtungsdate.value;
                }
                const response = await putBestandsaufnahme(ba.value.id, currentProject.value.id, copiedBa);
                updatedBaJson = (response.getDataFromResponse() as any).data as BestandsaufnahmeJson;
                await BestandsaufnahmeModel.insertOrUpdate({ data: updatedBaJson });
            } else {
                updatedBaJson = await ba.value.toClassJson();
                if (begehung) {
                    updatedBaJson.begehungsdatum = changedBegehungsdate.value;
                } else {
                    updatedBaJson.sichtungsdatum = changedSichtungsdate.value;
                }
                updatedBaJson.hasUnsavedChanges = true;
                await BestandsaufnahmeModel.insertOrUpdate({ data: updatedBaJson });
            }
            updatedBaJson.isLocal = false; // legacy property, make sure it is not set to true
            const updatedBa = new Bestandsaufnahme(updatedBaJson);
            store.commit('currentHzba/setCurrentBa', updatedBa);

            if (begehung) {
                isUpdatingBegehungsDate.value = false;
            } else {
                isUpdatingSichtungsDate.value = false;
            }
        } catch (error) {
            console.error(`Error while setting ${begehung ? "Begehungsdatum" : "Sichtungsdatum"} manually`, error);
            updateErrorDate();
        } finally {
            isPerformingDatabaseUpdate.value = false;
        }
    };

    const saveBegehungsDate = () => saveDateToDatabase(true);
    const saveSichtungsDate = () => saveDateToDatabase(false);

    watch(() => ba.value?.begehungsdatum, (newVal) => {
        if (newVal) {
            changedBegehungsdate.value = moment(newVal).format();
        }
    });

    watch(() => ba.value?.sichtungsdatum, (newVal) => {
        if (newVal) {
            changedSichtungsdate.value = moment(newVal).format();
        }
    });

    return {
        isPerformingDatabaseUpdate,
        isUpdatingBegehungsDate,
        isUpdatingSichtungsDate,
        changedBegehungsdate,
        changedSichtungsdate,
        begehungsDateLocalizedString,
        sichtungsDateLocalizedString,
        saveBegehungsDate,
        saveSichtungsDate
    };
}